import * as React from "react";
import { graphql, StaticQuery } from "gatsby";

const WriterBox = () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        let img_src = data.file.publicURL;
        let about = data.site.siteMetadata.author.about.slice(0,2);
        return (
          <div className="grid md:grid-cols-6 justify-items-center content-center gap-6 md:gap-10 border-y-2 border-neutral-700 py-6 my-6">
            <div className="w-32 md:w-auto md:col-span-2 rounded-full h-fit bg-indigo-400 hover:transparent hover:border-4 border-indigo-400 transition-all duration-200">
              <div className="mix-blend-multiply hover:mix-blend-normal">
                <img className="rounded-full" src={img_src} alt="Silvi" />
              </div>
            </div>
            <div className="md:col-span-4 flex items-center">
              <div>
                <p className="text-neutral-500 dark:text-neutral-400 text-left">
                  Written by {data.site.siteMetadata.author.name}
                </p>
                {about.map((paragraph:string, index:string) => (
                  <p className="text-neutral-700 dark:text-neutral-300" key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

const query = graphql`
  query AuthorPictureQuery {
    file(name: { eq: "ProfilePic" }) {
      publicURL
    }
    site {
      siteMetadata {
        author {
          name
          about
        }
      }
    }
  }
`;

export default WriterBox;
