import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { H2 } from "../../components/Header";
import { FilledButton } from "../../components/Button";
import Seo from "../../components/Seo";
import WriterBox from "../../components/Blog/WriterBox";

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";

interface blogPostProps {
  data: any;
}

const BlogPost = ({ data }: blogPostProps) => {
  deckDeckGoHighlightElement();
  let img_src: string = data.mdx.frontmatter.featuredImage
    ? data.mdx.frontmatter.featuredImage.publicURL
    : data.file.publicURL;
  return (
    <div className="container min-h-screen max-w-2xl mx-auto flex flex-col pt-24 pb-20">
      <Seo
        frontmatter={data.mdx.frontmatter}
        postImage={img_src}
        isBlogPost={true}
      />
      <FilledButton
        className="w-fit text-sm bg-gray-400 hover:bg-gray-300 text-gray-700 py-1 px-2 mb-4"
        text="❮ All Posts"
        href="/blog"
      />
      <article className="prose max-w-2xl dark:prose-invert prose-img:rounded-2xl prose-a:text-indigo-400">
        <header className="mb-4">
          <h1 className="mb-2"> {data.mdx.frontmatter.title} </h1>
          <p className="text-sm m-0 text-gray-500">{data.mdx.frontmatter.date}</p>
        </header>
        <section className="featuredImage">
          <img
            className="rounded-2xl mb-4"
            src={img_src}
            alt={data.mdx.frontmatter.title}
          />
        </section>
        <section className="content">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </section>
      </article>
      <WriterBox />
    </div>
  );
};

export const articleQuery = graphql`
  query articleQuery($id: String) {
    file(name: { eq: "DefaultBlogImg" }) {
      publicURL
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        featuredImage {
          publicURL
          extension
        }
      }
      body
    }
  }
`;

export default BlogPost;
